
























import {
  Component,
  Mixins,
  Ref,
} from "vue-property-decorator";

import StoreFrontsMixin from "@/mixins/http/StoreFrontsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import StoreFrontData from "@/components/StoreFront/Form.vue";
import { RequestData } from "@/store/types/RequestStore";
import { showErrorToast, showToast } from "@/utils/Toast";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { StoreFront } from "@/models";
import { easync } from "@/utils/http";

const getItemTag = "get_store_front";
const updateItemTag = "update_store_front";

@Component({
  components: {
    StoreFrontData,
    VPageContentSticky,
  },
})
export default class StoreFrontShow extends Mixins(StoreFrontsMixin) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(updateItemTag) updateItemRequest: RequestData;
  @Ref() diffsData: HTMLElement;
  item: StoreFront = null;

  mounted() {
    this.load();
  }

  get id() {
    return parseInt(this.$route.params.id);
  }

  async update(data: any = null) {
    const payload = { id: this.item.id, ...data };
    const [res, errors] = await easync(
      this.updateStoreFront(payload, updateItemTag)
    );

    if (errors) {
      showErrorToast("Errore nell'aggiornamento dell'app");
      return;
    }

    this.item = res;
    showToast("App aggiornata!");
  }

  async load() {
    const [res, errors] = await easync(
      this.getStoreFront(this.id, getItemTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.item = res;
  }

  get loading() {
    return this.getItemRequest?.loading;
  }

  get meta() {
    return this.getItemRequest?.meta;
  }
}
