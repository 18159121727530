



































import { Vue, Component, Prop } from 'vue-property-decorator';
import VColor from '@/components/VColor.vue';
import { Chrome } from 'vue-color';
import ClickOutside from '@/directives/ClickOutside';
import { showToast } from '@/utils/Toast';

@Component({
  directives: { ClickOutside },
  components: {
    VColor,
    Chrome
  }
})
export default class MultiColorInput extends Vue {
  @Prop() value: string[];
  @Prop({ default: 'md' }) size: string;
  @Prop({ default: true }) addable: boolean;
  @Prop({ default: true }) deletable: boolean;

  private colors: string[] = [];
  created() {
    this.colors = this.value || [];
  }

  get colorAddClass() {
    return `color-add-${this.size}`;
  }

  color: any = null;

  open = false;

  closeDropdown() {
    this.$nextTick(() => {
      if (this.open) {
        this.open = false;
      }
    });
  }

  colorConfirmed() {
    this.open = false;
    if (!this.colors.find(o => o === this.color.hex)) {
      this.colors.push(this.color.hex);
    } else {
      showToast('Questo colore esiste già', { type: 'info' });
    }
  }

  destroy(color: string) {
    this.colors = this.colors.filter(o => o !== color);
    this.$emit('input', this.colors);
  }

  add() {
    if (!this.open) {
      this.color = { hex: '#000000' };
    }

    this.open = !this.open;
  }

  click(color: { hex: string }) {
    this.color = color.hex;
  }
}
