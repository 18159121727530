







































import { StoreFront } from '@/models';
import { Vue, Component, Prop } from 'vue-property-decorator';
import MultiColorInput from '@/components/MultiColorInput.vue';

@Component({
  components: {
    MultiColorInput
  }
})
export default class StoreFrontForm extends Vue {
  @Prop() item: StoreFront;

  emitUpdate(data: StoreFront) {
    this.$emit('updated', data);
  }

  private colors = ['#000000'];
}
